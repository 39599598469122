import { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import WebsiteLayout, { NavbarTheme } from "src/layouts/WebsiteLayout";
import NotFound from "src/components/Website/NotFound";
import { useToastContext } from "src/hooks/useToastContext";
const NotFoundPage = () => {
  const router = useRouter();
  const {
    showSuccess
  } = useToastContext();

  // Redirect if deprecated "workflow" query param is present
  useEffect(() => {
    if (router.isReady && router.query.workflow) {
      showSuccess("Redirecting you...");
      const {
        workflow
      } = router.query;
      const otherQueryParams = new URLSearchParams(window.location.search);
      otherQueryParams.delete("workflow");
      const pagePath = router.asPath.split("?")[0]?.split("/app/")[1];
      const queryParamString = otherQueryParams.toString();
      const newPath = `/app/workflow/${workflow}/${pagePath}${queryParamString ? `?${queryParamString}` : ""}`;
      console.warn("Using deprecated query param. Redirecting to new route.");
      void router.replace(newPath);
    }
  }, [router, showSuccess]);
  return <>
      <Head>
        <title>404 Not Found - Integral</title>
      </Head>
      <NotFound />
    </>;
};
NotFoundPage.suppressFirstRenderFlicker = true;
NotFoundPage.getLayout = (page: JSX.Element) => <WebsiteLayout navbarTheme={NavbarTheme.Dark} title="Integral | Expert Certifications-as-a-Service">
    {page}
  </WebsiteLayout>;
export default NotFoundPage;