import React from "react"

import { Button, Flex, Text, VStack } from "@chakra-ui/react"
import Link from "next/link"

const NotFound = () => {
  return (
    <VStack align="stretch" spacing={0}>
      <Flex flexDirection="column" backgroundImage="var(--website-gradient-background-image)" align={[null, "center"]} paddingBottom={["100px", "140px"]}>
        <Text
          color="white"
          fontWeight="var(--website-font-weight-h1)"
          fontSize={{ base: "32px", md: "44px", lg: "56px" }}
          textAlign={["left", "center"]}
          lineHeight="112%"
          mb="20px"
          mt={{ base: "140px", md: "196px" }}
          px={{ base: "32px", md: "72px" }}
          w={["100%", "80%", "75%"]}
          maxWidth="var(--website-width-max)"
        >
          404 Error
        </Text>

        <Text
          color="var(--website-color-p)"
          fontSize={["var(--website-font-size-p-small)", "var(--website-font-size-p)"]}
          maxWidth="668px"
          textAlign={["left", "center"]}
          ml={["32px", "0"]}
          mr={["64px", "0"]}
          mb="60px"
        >
          Oops! Looks like you&apos;ve ventured into the data wilderness. Let&apos;s steer you back to familiar bytes – click the refresh button or contact our cyber Sherpas for an express route back.
        </Text>

        <Link href="/">
          <Button
            color="white"
            bg="var(--website-color-highlight)"
            borderRadius="50px"
            _hover={{
              bg: "var(--website-color-highlight)",
            }}
            fontSize="18px"
            ml={["32px", "0"]}
          >
            Get to Homebase
          </Button>
        </Link>
      </Flex>
    </VStack>
  )
}

export default NotFound
